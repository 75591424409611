import React from 'react'
import { Grid, Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core'
import { commas } from 'helpers'
import PrintLogo from 'assets/img/tss-logo.png'
import QRCode from 'qrcode.react'
import TSSLogo from 'assets/img/tss-color.png'

const ThermalBOL = ({ bolQR, total, componentRef, invInters, bolData }) => {
  const bolDate = {
    date: new Date(Date.parse(bolData.processed_at)).toLocaleString('en-US', {
      month: '2-digit',
      day: '2-digit',
      year: '2-digit'
    }),
    time: new Date(Date.parse(bolData.processed_at)).toLocaleString('en-US', {
      hour: 'numeric',
      minute: '2-digit',
      hour12: true,
      timeZoneName: 'short'
    })
  }

  return (
    <div className="thermal preview-container" ref={componentRef}>
      <div className="title">
        <p>STRAIGHT BILL OF LADING</p>
        <p>SHORT FROM ORIGINAL - NOT NEGOTIABLE</p>
      </div>

      <div className="header">
        <img src={PrintLogo} alt="tss-print-logo" style={{ width: 50, height: 'auto' }} />
        <div className="bol-number">
          <div className="label">BOL #</div>
          <div>{bolData.bolId}</div>
        </div>
        <div className="date-time">
          <div>{bolDate.date}</div>
          <div className="time">{bolDate.time}</div>
        </div>
      </div>

      <div className="basic-info">
        <p>
          Terminal: <strong>{bolData.terminal}</strong>
        </p>
        <p>
          PO #: <strong>{bolData.purchase_order_number}</strong>
        </p>
        <p>
          Carrier: <strong>{bolData.carrier}</strong>
        </p>
        <p>
          Driver: <strong>{bolData.driver_name}</strong>
        </p>
        <p>
          Trailer #: <strong>{bolData.trailer_number}</strong>
        </p>
        <p>
          Truck #: <strong>{bolData.truck_number}</strong>
        </p>
        <p>
          Producer/Buyer: <strong>{bolData.buyer}</strong>
        </p>
        <p>
          Well: <strong>{bolData.job_name}</strong>
        </p>
        <p>
          Status: <strong>{bolData.status}</strong>
        </p>
        <p>
          Sand Type: <strong>{bolData.sand_type}</strong>
        </p>
      </div>

      <div className="qr-section">
        <QRCode
          value={JSON.stringify(bolQR)}
          size={150}
          className="qr-code"
          level="L"
          imageSettings={{
            src: TSSLogo,
            height: 20,
            width: 20,
            excavate: true
          }}
        />
      </div>
      <div className="legal-section">
        <p>
          Subject to Section 7 of conditions of applicable Bill of Lading. If this shipment is to be delivered to the
          consignee, without recourse on the consignor, the consignor shall sign the following statement. The carrier
          may decline to make delivery of this shipment without payment of freight and all other lawful charges. The
          property described below is in apparent good condition except as noted.
        </p>
        <p>
          <strong>WARNING:CONTAINS FREE SILICA - DO NOT BREATHE DUST</strong>
        </p>
        <p>
          Prolonged exposure to dust may cause delayed lung injury (silicosis). Monographs on the Evaluation of the
          Carcinogenic Risk of Chemicals to Humans (vol 68, 1997) concludes that there is sufficient evidence in humans
          for the carcinogenicity of inhaled crystalline sillica in the forms of quartz and cristobalite (Group I) in
          certain industrial circumstances, but that carcinogenicity may be dependent on inherent characteristics of the
          crystalline silica or on external factors affecting its biological activity or distribution activity or
          distribution of its polymorphs. See Material Safety Data Sheet for detailed information. (CAS 14808-60-7)
        </p>
      </div>
      <Table size="small" className="thermal-table">
        <TableHead>
          <TableRow>
            <TableCell>Sand Type</TableCell>
            <TableCell>Rail Car</TableCell>
            <TableCell align="right">Shipping Weight - (lbs)</TableCell>
            <TableCell align="right">(tons)</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {invInters.map((row, index) => {
            const weight =
              index === 0
                ? row.gross_weight - bolData.tare_weight
                : row.gross_weight - invInters[index - 1].gross_weight
            const tons = weight / 2000

            return (
              <TableRow key={row.inventory_id}>
                <TableCell>{bolData.sand_type}</TableCell>
                <TableCell>{row.inventory_id}</TableCell>
                <TableCell align="right">{commas(weight)}</TableCell>
                <TableCell align="right">{tons}</TableCell>
              </TableRow>
            )
          })}
          <TableRow className="subtotal-row">
            <TableCell></TableCell>
            <TableCell>
              <strong>Sub Total</strong>
            </TableCell>
            <TableCell align="right">{commas(total)}</TableCell>
            <TableCell align="right">{total / 2000}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell></TableCell>
            <TableCell>
              <strong>Tare</strong>
            </TableCell>
            <TableCell align="right">{commas(bolData.tare_weight)}</TableCell>
            <TableCell align="right">{bolData.tare_weight / 2000}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell></TableCell>
            <TableCell>
              <strong>Gross</strong>
            </TableCell>
            <TableCell align="right">{commas(bolData.tare_weight + total)}</TableCell>
            <TableCell align="right">{(bolData.tare_weight + total) / 2000}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
      <Grid container className="GridContainer">
        <Grid item xs={6} className="GridItem certification-text">
          <p>
            <strong>SHIPPER CERTIFICATION</strong>
          </p>
          <p>
            This is to certify that the above-named materials are properly classified, described, packaged, marked and
            labeled, and are in proper condition for transportation according to the applicable regulations of the
            Department of Transportation (DOT).
          </p>
        </Grid>
        <Grid item xs={6} className="GridItem certification-text">
          <p>
            <strong>CARRIER CERTIFICATION</strong>
          </p>
          <p>
            Carrier acknowledges receipt of material and packing in proper condition for shipment. Any damages and or
            defects in packaging and material not noted before shipment leaves Texas Speciality Sands facilities is the
            responsibility of the carrier. Carrier has reviewed BOL including but not limited to grade, PO, well, and/or
            operator. Carrier acknowledges that the BOL is accurate
          </p>
        </Grid>
        <Grid item xs={6} className="GridItem">
          <div className="signature-box">{bolData.weighmaster_license}</div>
        </Grid>
        <Grid item xs={6} className="GridItem">
          <div className="signature-box">{bolData.driver_name}</div>
        </Grid>
        <Grid item xs={6} className="GridItem certification-text">
          <p>
            <strong>FREIGHT COLLECT</strong>
          </p>
          <p>
            If this shipment is to be delivered to the consignee, without recourse on the cosignor, the cosignor shall
            sign the following statement:
          </p>
        </Grid>
        <Grid item xs={6} className="GridItem certification-text">
          <p>
            <strong>ITEMS RECEIVED IN GOOD</strong>
          </p>
        </Grid>
        <Grid item xs={6} className="GridItem">
          <div style={{ borderBottom: '1px solid black', height: 50 }}></div>
          <p className="legal-text">Signature of Consignor</p>
        </Grid>
        <Grid item xs={6} className="GridItem">
          <div style={{ borderBottom: '1px solid black', height: 50 }}></div>
          <p className="legal-text">Signature of Consignor</p>
        </Grid>
      </Grid>
    </div>
  )
}

export default ThermalBOL
