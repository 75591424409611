import React from 'react'
import { Grid, Table, TableBody, TableCell, TableHead, TableRow, Paper } from '@material-ui/core'
import { createDateString, commas } from 'helpers'
import 'assets/css/PrintBOLModal.css'
import PrintLogo from 'assets/img/tss-logo.png'
import QRCode from 'qrcode.react'
import TSSLogo from 'assets/img/tss-color.png'

const LetterBOL = ({
  date,
  bolQR,
  total,
  componentRef,
  invInters,
  bolData: {
    bolId,
    tare_weight,
    processed_at,
    terminal,
    carrier,
    driver_name,
    trailer_number,
    truck_number,
    status,
    weighmaster_license,
    buyer,
    purchase_order_number,
    sand_type,
    job_name
  }
}) => {
  return (
    <div className="letter" ref={componentRef}>
      <Grid container className="GridContainer">
        <Grid item xs={12} className="GridItem">
          <h3
            style={{
              fontSize: '14px',
              fontWeight: 'bold',
              marginBottom: '2em'
            }}
          >
            STRAIGHT BILL OF LADING - SHORT FORM ORIGINAL - NOT NEGOTIABLE
          </h3>
        </Grid>
        <Grid item xs={4} sm={4} md={4} id="bol-details" style={{ fontSize: '12px' }} className="GridItem">
          <img src={PrintLogo} alt="tss-print-logo" style={{ width: 150, height: 'auto' }} />
          <p className="left-info">
            BOL #: <strong>{bolId}</strong>
          </p>
          <p className="left-info">
            Date: <strong>{date}</strong>
          </p>
          <p className="left-info">
            Terminal: <strong>{terminal}</strong>
          </p>
          <p className="left-info">
            PO #: <strong>{purchase_order_number}</strong>
          </p>
          <p className="left-info">
            Carrier: <strong>{carrier}</strong>
          </p>
          <p className="left-info">
            Driver Name: <strong>{driver_name}</strong>
          </p>
          <p className="left-info">
            Trailer Number: <strong>{trailer_number}</strong>
          </p>
          <p className="left-info">
            Producer / Buyer: <strong>{buyer}</strong>
          </p>
          <p className="left-info">
            Well: <strong>{job_name}</strong>
          </p>
          <p className="left-info">
            Truck Number: <strong>{truck_number}</strong>
          </p>
          <p className="left-info" style={{ textTransform: 'capitalize' }}>
            Status: <strong>{status}</strong>
          </p>
          <p className="left-info">
            Processed at: <strong>{createDateString(processed_at)}</strong>
          </p>
        </Grid>

        <Grid container item xs={8} className="GridContainer">
          <Grid item xs={4} sm={4} md={4} className="GridItem">
            <QRCode
              size={150}
              value={JSON.stringify(bolQR)}
              level={'L'}
              imageSettings={{
                src: TSSLogo,
                x: null,
                y: null,
                height: 30,
                width: 30,
                excavate: true
              }}
            />
          </Grid>
          <Grid item xs={8} sm={8} md={8} className="GridItem">
            <p className="legal-text">
              Subject to Section 7 of conditions of applicable Bill of Lading. If this shipment is to be delivered to
              the consignee, without recourse on the consignor, the consignor shall sign the following statement. The
              carrier may decline to make delivery of this shipment without payment of freight and all other lawful
              charges. The property described below is in apparent good condition except as noted.
            </p>
          </Grid>
          <Grid item xs={12} className="GridItem">
            <p className="legal-text">
              <strong>WARNING:CONTAINS FREE SILICA - DO NOT BREATHE DUST</strong>
            </p>
            <p className="legal-text">
              Prolonged exposure to dust may cause delayed lung injury (silicosis). Monographs on the Evaluation of the
              Carcinogenic Risk of Chemicals to Humans (vol 68, 1997) concludes that there is sufficient evidence in
              humans for the carcinogenicity of inhaled crystalline sillica in the forms of quartz and cristobalite
              (Group I) in certain industrial circumstances, but that carcinogenicity may be dependent on inherent
              characteristics of the crystalline silica or on external factors affecting its biological activity or
              distribution activity or distribution of its polymorphs. See Material Safety Data Sheet for detailed
              information. (CAS 14808-60-7)
            </p>
          </Grid>
        </Grid>
        <Paper className="print-root">
          <Table className="print-table" aria-label="spanning table" size="small">
            <TableHead>
              <TableRow>
                <TableCell>Sand Type</TableCell>
                <TableCell align="right">Rail Car</TableCell>
                <TableCell align="right">Shipping Weight - (lbs)</TableCell>
                <TableCell align="right">(tons)</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {invInters.map((row, index) => (
                <TableRow key={row.inventory_id + row.gross_weight}>
                  <TableCell>{sand_type}</TableCell>
                  <TableCell align="right">{row.inventory_id}</TableCell>
                  <TableCell align="right">
                    {(index === 0 && row.gross_weight - tare_weight < 0) ||
                    (index > 0 && row.gross_weight - invInters[index - 1].gross_weight < 0)
                      ? '-'
                      : ''}
                    {commas(
                      index === 0
                        ? row.gross_weight - tare_weight
                        : row.gross_weight - invInters[index - 1].gross_weight
                    )}
                  </TableCell>
                  <TableCell align="right">
                    {String(
                      index === 0
                        ? (row.gross_weight - tare_weight) / 2000
                        : (row.gross_weight - invInters[index - 1].gross_weight) / 2000
                    )}
                  </TableCell>
                </TableRow>
              ))}

              <TableRow style={{ borderTop: '2px solid black' }}>
                <TableCell rowSpan={3} />
                <TableCell align="right">
                  <strong>Sub Total</strong>
                </TableCell>
                <TableCell align="right">{commas(total)}</TableCell>
                <TableCell align="right">{total / 2000}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="right">
                  <strong>Tare</strong>
                </TableCell>
                <TableCell align="right">{commas(tare_weight)}</TableCell>
                <TableCell align="right">{tare_weight / 2000}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="right">
                  <strong>Gross</strong>
                </TableCell>
                <TableCell align="right">{commas(tare_weight + total)}</TableCell>
                <TableCell align="right">{tare_weight / 2000 + total / 2000}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Paper>
      </Grid>
      <Grid container className="GridContainer">
        <Grid item xs={6} style={{ marginTop: 5 }} className="GridItem">
          <p className="legal-text">
            <strong>SHIPPER CERTIFICATION</strong>
          </p>
          <p className="legal-text">
            This is to certify that the above-named materials are properly classified, described, packaged, marked and
            labeled, and are in proper condition for transportation according to the applicable regulations of the
            Department of Transportation (DOT).
          </p>
        </Grid>
        <Grid item xs={6} style={{ marginTop: 5 }} className="GridItem">
          <p className="legal-text">
            <strong>CARRIER CERTIFICATION</strong>
          </p>
          <p className="legal-text">
            Carrier acknowledges receipt of material and packing in proper condition for shipment. Any damages and or
            defects in packaging and material not noted before shipment leaves Texas Speciality Sands facilities is the
            responsibility of the carrier. Carrier has reviewed BOL including but not limited to grade, PO, well, and/or
            operator. Carrier acknowledges that the BOL is accurate
          </p>
        </Grid>
        <Grid item xs={6} className="GridItem">
          <p
            style={{
              border: '1px dashed black',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              height: '75px',
              width: '200px',
              margin: 0,
              fontFamily: 'sans-serif, serif'
            }}
          >
            {weighmaster_license}
          </p>
        </Grid>
        <Grid item xs={6} className="GridItem">
          <p
            style={{
              border: '1px dashed black',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              height: '75px',
              width: '200px',
              margin: 0,
              fontFamily: 'sans-serif, serif'
            }}
          >
            {driver_name}
          </p>
        </Grid>
        <Grid item xs={6} style={{ marginTop: 5 }} className="GridItem">
          <p className="legal-text">
            <strong>FREIGHT COLLECT</strong>
          </p>
          <p className="legal-text">
            If this shipment is to be delivered to the consignee, without recourse on the cosignor, the cosignor shall
            sign the following statement:
          </p>
        </Grid>
        <Grid item xs={6} style={{ marginTop: 5 }} className="GridItem">
          <p className="legal-text">
            <strong>ITEMS RECEIVED IN GOOD</strong>
          </p>
        </Grid>
        <Grid item xs={6} style={{ marginTop: 5 }} className="GridItem">
          <div style={{ borderBottom: '1px solid black', height: 50 }}></div>
          <p className="legal-text">Signature of Consignor</p>
        </Grid>
        <Grid item xs={6} style={{ marginTop: 5 }} className="GridItem">
          <div style={{ borderBottom: '1px solid black', height: 50 }}></div>
          <p className="legal-text">Signature of Consignor</p>
        </Grid>
      </Grid>
    </div>
  )
}

export default LetterBOL
