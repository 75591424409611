import React, { useRef, useState, useContext } from 'react'
import { useReactToPrint } from 'react-to-print'
import { Dialog, FormControl, Select, MenuItem, InputLabel } from '@material-ui/core'
import { CardBody } from 'components/common/Card'
import 'assets/css/PrintBOLModal.css'
import { createDateString } from 'helpers'
import LetterBOL from './LetterBOL'
import ThermalBOL from './ThermalBOL'
import { ReducerContext } from 'App'

const BOLPrint = ({
  bolData: {
    bolId,
    gross_weight,
    tare_weight,
    processed_at,
    terminal,
    buyer,
    purchase_order_number,
    sand_type,
    supplier
  },
  bolData,
  handleClose,
  open,
  specificInvInterData: { invInters }
}) => {
  const componentRef = useRef()

  const {
    state: { currentTerminal }
  } = useContext(ReducerContext)

  const [printFormat, setPrintFormat] = useState(currentTerminal === 'Wyalusing' ? 'thermal' : 'letter')
  const printClickHandler = useReactToPrint({ content: () => componentRef.current })
  const BOLComponent = { letter: LetterBOL, thermal: ThermalBOL }[printFormat]

  const date = new Date(processed_at).toLocaleDateString('en-US', {
    weekday: 'short',
    year: 'numeric',
    month: 'short',
    day: 'numeric'
  })

  const bolQR = {
    'Ticket No': `${bolId}`,
    Customer: buyer,
    'PO #': purchase_order_number,
    Product: sand_type,
    Gross: `${gross_weight}`,
    Net: `${gross_weight - tare_weight}`,
    Tare: `${tare_weight}`,
    Date: createDateString(processed_at),
    Vendor: supplier,
    Facility: terminal
  }

  const total = invInters[invInters.length - 1].gross_weight - tare_weight

  return (
    <div>
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" fullWidth={true} maxWidth="md">
        <CardBody className="">
          <div className="print-controls hide-on-print">
            <button className="hide-on-print" id="printButton" onClick={printClickHandler}>
              Print BOL #{bolId}
            </button>
            <FormControl variant="outlined" style={{ minWidth: 200, marginRight: 16 }}>
              <InputLabel>Print Format</InputLabel>
              <Select value={printFormat} onChange={e => setPrintFormat(e.target.value)} label="Print Format">
                <MenuItem value="letter">Letter Size</MenuItem>
                <MenuItem value="thermal">Thermal (3.15" wide)</MenuItem>
              </Select>
            </FormControl>
          </div>
          <BOLComponent {...{ date, bolQR, total, bolData, componentRef, invInters }} />
        </CardBody>
      </Dialog>
    </div>
  )
}

export default BOLPrint
